import PropTypes from 'prop-types';
import styles from './index.module.scss';
// TODO: Cambiar los colores del rgb por el tema de la tienda
const Spinner = ({ button }) => (
  <div className={styles.spinner}>
    <div className={styles.image}>
      <div
        className={styles.border}
        style={
          button
            ? {
                background: 'linear-gradient(black, transparent) border-box',
                height: '30px',
                width: '30px'
              }
            : {}
        }
      />
    </div>
  </div>
);

Spinner.propTypes = { button: PropTypes.bool };
Spinner.defaultProps = { button: false };

export default Spinner;
